import { Center } from "./Center";
import { Randomizer } from "./Randomizer";

export function App() {
  return (
    <Center>
      <h1>Feeling adventurous?</h1>
      <Randomizer />
    </Center>
  );
}
