import { ReactNode } from "react";
import { DifficultyBadge, DifficultyMessage, StatusBadge, StatusMessage } from "../Badges";
import { Hike } from "../shared";

import style from "./index.module.scss";

interface ConditionalProps {
  show: boolean;
  children: ReactNode;
}
function Conditional({ show, children }: ConditionalProps) {
  if (show) {
    return <>{ children }</>;
  } else {
    return null;
  }
}

function HikeName(hike: Hike) {
  return (
    <h2>
      <a href={hike.url}>{hike.name}</a>
    </h2>
  );
}

function HikeLocation(hike: Hike) {
  return (
    <p>
      <a href={hike.areaInfo}>{hike.area}</a>{" "}
      <Conditional show={!!hike.area}>@</Conditional> {hike.city}
      <Conditional show={!!hike.city}>,</Conditional> {hike.state}
    </p>
  );
}

function HikeStatus(hike: Hike) {
  return (
    <div className={style.status}>
      <StatusMessage status={hike.trailStatus} />
      <StatusBadge status={hike.trailStatus} />
      <div className={style.gap}></div>
      <DifficultyMessage difficulty={hike.difficulty} />
      <DifficultyBadge difficulty={hike.difficulty} />
    </div>
  );
}

export function HikeComponent(hike: Hike) {
  return (
    <div className={style.grid}>
      <div className={style.cell1}>
        <HikeName {...hike} />
        <HikeLocation {...hike} />
      </div>
      <div className={style.cell2}>
        <HikeStatus {...hike} />
      </div>
    </div>
  );
}
