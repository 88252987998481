import { useState } from "react";
import { Hike } from "./shared";
import { HikeComponent } from "./Hike";

import { ReactComponent as Caret } from "./resources/caret-right.svg";

import style from "./index.module.scss";

const HIKES: Hike[] = require("./hikes.json");

const REJECT_MESSAGES = [
  "Roll the dice!",
  "Try again!",
  "Adventure awaits... elsewhere!",
  "Eeny, meeny, miny, moe...",
  "Not feeling it!",
  "Surprise me again!",
  "Onward to the unknown!",
  "Let's try that again!",
  "Another one, please!",
  "Keep searching!",
  "Any others?",
  "Definately a miss!",
  "Spin the wheel!",
  "Reject and proceed!",
  "NOPE!",
  "Next!"
];

function chooseRandom<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function Randomizer() {
  const nextHike = () => chooseRandom(HIKES);
  const nextMsg = () => chooseRandom(REJECT_MESSAGES);
  
  const [hike, setHike] = useState(nextHike());
  const [msg, setMsg] = useState(nextMsg());

  const onClick = () => { setHike(nextHike); setMsg(nextMsg); }

  return (
    <>
      <HikeComponent {...hike} />
      <button onClick={onClick} className={style.btn}>
        {msg} <Caret />
      </button>
    </>
  )
}
