import { ReactNode } from "react"

import style from "./index.module.css";

interface CenterProps {
  children: ReactNode
}

export function Center(props: CenterProps) {
  return (
    <div className={style.center}>
      {props.children}
    </div>
  )
}