import { ReactComponent as Ok } from "./bootstrap/check-circle-fill.svg";
import { ReactComponent as Warning } from "./bootstrap/exclamation-circle-fill.svg";
import { ReactComponent as Closed } from "./bootstrap/x-circle-fill.svg";

import { ReactComponent as Circle } from "./trailforks/circle.svg";
import { ReactComponent as Square } from "./trailforks/square.svg";
import { ReactComponent as Triangle } from "./trailforks/triangle.svg";
import { ReactComponent as Diamond } from "./trailforks/diamond.svg";
import { ReactComponent as DoubleDiamond } from "./trailforks/double-diamond.svg";

export const DIFFICULTY_LEVELS = [
  "Easiest / White Circle",
  "Easy / Green Circle",
  "Intermediate / Blue Square",
  "Advanced: Grade 4",
  "Difficult / Red",
  "Very Difficult / Black Diamond",
  "Extremely Difficult / Dbl Black Diamond",
  "Extremely Difficult & dangerous, pros only!",
  "Access Trail, Road or Doubletrack",
  "Secondary Access Road/Trail",
  "Chairlifts & gondolas"
] as const;
export type Difficulty = (typeof DIFFICULTY_LEVELS)[number];

export const STATUSES = [
  "All Clear / Green",
  "Minor Issue / Yellow",
  "Significant Issue / Amber",
  "Closed / Red"
] as const;
export type Status = (typeof STATUSES)[number];

const GREEN = "#198754"
const RED = "#dc3545"
const BLACK = "#000000";

interface StatusBadgeProps {
  status: Status;
}
export function StatusBadge({ status }: StatusBadgeProps) {
  switch (status) {
    case "All Clear / Green":
      return <Ok color={GREEN} />
    
    case "Minor Issue / Yellow":
      return <Warning color="#FFCC00" />

    case "Significant Issue / Amber":
      return <Warning color="#FF8500" />

    case "Closed / Red":
      return <Closed color={RED} />
  }
}
export function StatusMessage({ status }: StatusBadgeProps) {
  switch (status) {
    case "All Clear / Green":
      return <>All Clear</>
    
    case "Minor Issue / Yellow":
      return <>Minor Issue</>

    case "Significant Issue / Amber":
      return <>Significant Issue</>

    case "Closed / Red":
      return <>Closed</>
  }
}

interface DifficultyBadgeProps {
  difficulty: Difficulty;
}
export function DifficultyBadge({ difficulty }: DifficultyBadgeProps) {
  switch (difficulty) {
    case "Easiest / White Circle":
      return <Circle color="#EAEAEA" style={{"filter": "drop-shadow(0 0 2px black)"}}/>
    
    case "Easy / Green Circle":
      return <Circle color={GREEN} />

    case "Intermediate / Blue Square":
      return <Square color="#148CF0" />

    case "Advanced: Grade 4":
      return <Square color="#0B4B81" />

    case "Difficult / Red":
      return <Triangle color={RED} />
    
    case "Very Difficult / Black Diamond":
      return <Diamond color={BLACK} />

    case "Extremely Difficult / Dbl Black Diamond":
      return <DoubleDiamond color={BLACK} />

    case "Extremely Difficult & dangerous, pros only!":
      return <DoubleDiamond color="#FF8500" />
    
    case "Access Trail, Road or Doubletrack":
      return <Triangle color="#854E85" />

    case "Secondary Access Road/Trail":
      return <Triangle color="#D9D9B5" />

    case "Chairlifts & gondolas":
      return <Triangle color="#666666" />
  }
}
export function DifficultyMessage({ difficulty }: DifficultyBadgeProps) {
  switch (difficulty) {
    case "Easiest / White Circle":
      return <>Very Easy</>
    
    case "Easy / Green Circle":
      return <>Easy</>

    case "Intermediate / Blue Square":
      return <>Intermediate</>

    case "Advanced: Grade 4":
      return <>Advanced</>

    case "Difficult / Red":
      return <>Difficult</>
    
    case "Very Difficult / Black Diamond":
      return <>Very Difficult</>

    case "Extremely Difficult / Dbl Black Diamond":
      return <>Extremely Difficult</>

    case "Extremely Difficult & dangerous, pros only!":
      return <>Pros Only!</>
    
    case "Access Trail, Road or Doubletrack":
      return <>Access Trail, Road or Doubletrack</>

    case "Secondary Access Road/Trail":
      return <>Secondary Access Road/Trail</>

    case "Chairlifts & gondolas":
      return <>Chairlifts & Gondolas</>
  }
}